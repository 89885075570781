/* Date Picker */

.react-datepicker, .react-datepicker__month-container {
  @apply !border-none !rounded-md !capitalize
}

.react-datepicker__header  {
  @apply !bg-influentia-black !text-white !font-montserrat
}

.react-datepicker__triangle {
  @apply !text-influentia-black
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  @apply !fill-influentia-black
}

.react-datepicker__month, .react-datepicker__month-container {
  @apply !bg-dark-blue !text-white
}

.react-datepicker__current-month, .react-datepicker__day-name  {
  @apply !text-white
}

.react-datepicker__day {
  @apply !text-white hover:!text-dark-blue hover:!bg-white
}

.react-datepicker__day--today {
  @apply !bg-influentia-black !rounded-[0.3rem] hover:!text-dark-blue hover:!bg-white
}

.react-datepicker__day--selected {
  @apply !bg-influentia-light-blue !text-dark-blue  hover:!bg-influentia-light-blue
}

/* SCROLLBARS */
/* width */
.notifications-scrollbar::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.notifications-scrollbar::-webkit-scrollbar-track {
  background: #cdd9e7;
  border-radius: 20px;
}

/* Handle */
.notifications-scrollbar::-webkit-scrollbar-thumb {
  background: #16162c;
  border-radius: 20px;
}

/* Handle on hover */
.notifications-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #16162c;
}

/* LOADER CONTAINER */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* LOADER */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #2e4347;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.lds-dual-ring::after {
  border: 2px solid white !important;
  border-color: white transparent white transparent !important;
}

/* Tables: Selector classes, Do not remove empty classes */
.cell-class {
}

.table-checkbox-th {
}

input.table-checkbox-th[type="checkbox"]:checked {
  background-color: white;
  accent-color: black;
}

input.table-checkbox-th[type="checkbox"]:checked::before {
  content: "-";
  color: black;
}


/* Dropwdown */

.dropdown-container select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  background: #ffffff;
}

.dropdown-container select::-ms-expand {
  display: none;
}

.dropdown-container select > option {
  background-color: #16162C;
}


@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* LOADER SMALL */

.lds-dual-ring {
  display: inline-block;
  width: 30px;
  height: 100%;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #2e4347;
  border-color: #2e4347 transparent #2e4347 transparent;
  animation: lds-dual-ring 0.4s linear infinite;
}

/* LOADER SMALL YELLOW */
.lds-dual-ring-light-green {
  display: inline-block;
  width: 30px;
  height: 100%;
}
.lds-dual-ring-light-green:after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #b2bfc1;
  border-color: #b2bfc1 transparent #b2bfc1 transparent;
  animation: lds-dual-ring 0.4s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.internalNotes::-webkit-scrollbar {
  width: 6px;
}
 
.internalNotes::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: white;
}
 
.internalNotes::-webkit-scrollbar-thumb {
  border-radius: 20px;
  @apply bg-influentia-black
}

.TimeLine::-webkit-scrollbar {
  width: 6px;
}
 
.TimeLine::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: white;
}
 
.TimeLine::-webkit-scrollbar-thumb {
  border-radius: 20px;
  @apply bg-dark-blue
}



.customScroll::-webkit-scrollbar {
  @apply rounded-10;
  height: 7px;
}

.customScroll::-webkit-scrollbar-track {
  @apply bg-white rounded-10;
}

.customScroll::-webkit-scrollbar-thumb {
  @apply bg-dark-blue rounded-10;
}

.customScroll::-webkit-scrollbar-button {
  display: none;
}

.customScrollDashboard::-webkit-scrollbar {
  @apply rounded-10 w-2;
  height: 7px;
}

.customScrollDashboard::-webkit-scrollbar-track {
  @apply bg-white rounded-10;
}

.customScrollDashboard::-webkit-scrollbar-thumb {
  @apply bg-dark-blue-popups rounded-10;
}

.customScrollDashboard::-webkit-scrollbar-button {
  display: none;
}



.no-scroll {
  overflow: hidden;
}

.datePicker {
  width: 100%;
}

/* Snowflakes animation for auth loader in christmas */
.snowflake {
  --size: 1vw;
  width: var(--size);
  height: var(--size);
  background: white;
  border-radius: 50%;
  position: absolute;
  top: -5vh;
  opacity: .8;
}
@keyframes snowfall {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
}

/* added small blur every 6 snowflakes*/
.snowflake:nth-child(6n) {
  filter: blur(1px);
}